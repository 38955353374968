<template>
  <div class="main">
    <article class="category">
      <div class="container mx-auto lg:px-17 hidden lg:block">
        <div class="flex">
          <!-- Breadcrumbs designed specifically for category pages
          <div class="w-full">
            <breadcrumbs :routes="getBreadcrumbs" :active-route="category.name" />
          </div>
          -->
          <div class="breadcrumbs text-h6 uppercase py-5">
            <span>
              <router-link :to="localizedRoute('/')" class="hover:underline">
                HOME
              </router-link>
            </span>
          </div>
        </div>
      </div>
      <div class="uppercase text-center font-medium lg:text-heading leading-none lg:py-13 py-8 tracking-mid text-mid">
        REFILL YOUR FAVORITES
      </div>
      <div class="container mx-auto lg:px-6 pb-10 lg:pb-0 flex flex-wrap" v-if="refillProducts.length > 0">
        <product-tile
          class="flex-29% mb-10"
          v-for="(product, key) in refillProducts"
          v-if="product.product.category_ids"
          :key="key"
          :product="product.product"
          :show-add-to-cart-btn="showAddToCartBtn"
          :product-id="product.product.id"
          :category-id="product.product.category_ids[0]"
          :data-sku="product.product.sku"
          :data-type-id="product.product.type_id"
          :data-rewards-enabled="product.product.can_spend ? 1 : 0"
          :data-in-stock="product.product.stock && product.product.stock.is_in_stock ? 1 : 0"
        >
          <template v-slot:recommendedBadge>
            <recommended-badge
              v-if="product.product.category_ids"
              :type="'last-order'"
              :data="product.lastOrder"
              class="z-10 absolute top-0 right-0"
            ></recommended-badge>
          </template>
        </product-tile>
      </div>
    </article>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProductListing from 'theme/components/core/ProductListing'
import ProductTile from 'theme/components/core/ProductTile'
import RecommendedBadge from 'theme/components/core/RecommendedBadge'

export default {
  name: "Refill",
  components: {
    ProductListing,
    ProductTile,
    RecommendedBadge
  },
  data () {
    return {
      showAddToCartBtn: true
    }
  },
  mounted: function() {
    this.$store.dispatch("recommended/calculateHistoricalSuggestions")
  },
  computed: {
    ...mapState({
      historyByFrequency: state => state.recommended.historyByFrequency,
      historyByRecency: state => state.recommended.historyByRecency
    }),
    refillProducts() {
      return this.historyByFrequency.map(el => {return { product: el.product, lastOrder: Math.floor((new Date() - new Date(el.lastOrder))/(1000*60*60*24)) }})
    }
  }
}

</script>